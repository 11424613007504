@import "../../assets/css/variables.scss";

nav {
  position: absolute;
  width: 100%;
  z-index: 10;
  left: 0;
  top: 0;
  padding: 37px 32px;
  box-sizing: border-box;
  transition: all 0.3s;
  background-color: #000;
  .row > * {
    width: unset;
  }
  .switcher {
    hr {
      margin: 3px;
    }
    p {
      cursor: pointer;
      color: #fff;
    }
  }

  p {
    color: white;
    text-decoration: none;
    display: block;
    text-align: center;
    line-height: 1.6em;

    &[data-selected="true"] {
      color: #000;
      border-radius: 2px;
      background-color: $primary;
    }
  }

  .logo {
    transition: all 0.3s;
    position: absolute;
    top: 37px;
    left: 0;
    right: 0;
    margin: auto;
  }

  hr {
    transform: rotate(10deg);
    border: none;
    height: 1px;
    width: 100%;
    background-color: white;
  }

  &[data-dark="true"] {
    a {
      color: $black;

      &[data-selected="true"] {
        color: $primary;
      }
    }

    hr {
      background-color: black;
    }
  }

  &[data-scrolled="true"] {
    position: fixed;
    padding: 11px 23px;
    //background-image: radial-gradient(51% 81%, #202020 13%, #000000 100%);
    //background-image: url(../../static/images/about-us-background.jpg);
    background-size: cover;
    animation: slideIn 0.5s;

    .logo {
      top: 25px;
      height: 95px;
    }

    &[data-dark="true"] {
      background: white;
    }

    &[data-route="/about-us"] {
      background-image: url("/static/images/about-us-nav-background.png");
      background-size: cover;
    }
    &[data-route="/special"] {
      background-image: url("/static/images/about-us-nav-background.png");
      background-size: cover;
    }
    &[data-route="/cider"] {
      background-image: url("/static/images/about-us-nav-background.png");
      background-size: cover;
    }

    &[data-route="/brewpub"] {
      background-image: url("/static/images/brewpub-background.jpg");
      background-size: cover;
    }

    &[data-route="/brewery"] {
      background-image: url("/static/images/brewery-background.jpg");
      background-size: cover;
    }
    &[data-route="/beer"],
    &[data-route="/home"] {
      background-color: #000;
    }
  }

  &[data-route="/about-us"],
  &[data-route="/special"],
  &[data-route="/cider"],
  &[data-route="/brewpub"],
  &[data-route="/brewery"],
  &[data-route="/home"],
  &[data-route="/beer"]
  {
    background: unset;
  }

  &[data-route="/blog"],
  &[data-route="/blog-post"],
  &[data-route="/partnership"],
  &[data-route="/contact-us"],
  &[data-route="/assets"] {
    //background-image: url("/static/images/brewery-background.jpg");
    background-color: #fff;
    background-size: cover;
    path {
      fill: #000;
    }
    #Fill-47,
    #Fill-49,
    #Fill-45,
    #Fill-53 {
      fill: #000;
    }
    p {
      color: #000;
    }
    hr {
      background-color: #000;
    }
  }
}
