@import "../../assets/css/variables.scss";

#site-menu {
  position: fixed;
  padding: 30px 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background-color: black;
  border: 32px solid white;
  overflow-y: scroll;
  .nav-item{
    &:nth-child(-n+6){
        margin-bottom: 135px;
    }
  }
  h6 {
    color: white;
    font-size: 30px;
    text-align: center;
    margin-top: 43px;
    margin-bottom: 9px;
  }
  .logo{
    height: auto !important;
    display: flex;
    justify-content: center;
    align-self: end;
    margin-bottom: 30px;
  }
  button {
    &[data-action="close"] {
      position: fixed;
      width: 45px;
      height: 45px;
      top: 45px;
      left: 45px;
    }
  }
  a{
    text-decoration: none;
  }
  span.section {
    color: white;
    font-size: 20px;
    padding: 8px 0;
    text-decoration: underline;
    text-decoration-color: #000;
    text-underline-offset: 10px;
    transition: all 0.3s ease;
    text-decoration-thickness: 3px;

    &[data-selected="true"] {
      color: $primary;
      border-color: $primary;
      text-decoration-color: $primary;
    }

    &:hover {
      color: $primary;
      text-decoration-color: $primary;
    }
  }

  &[data-visible="true"] {
    display: block;
  }
}

.breake-word {
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  #site-menu {
    .nav-item{
      &:nth-child(-n+6){
        margin-bottom: 15px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  #site-menu {
    a.section{
      font-size: 18px;
    }
  }
}

@media screen and (max-width: $xs-width) {
  #site-menu {
    border-width: 14px;

    .col-md-4 {
      margin-bottom: 0 !important;
    }

    > .container > .row > .col-12 {
      height: 100%;
      padding-top: 38px;
    }

    a.section {
      display: block;
      text-align: center;
      margin: 0 !important;
    }

  }
}

@media screen and (min-width: 1024px) {
  #site-menu {
    overflow-y: hidden!important;
  }
}