@import "../../assets/css/variables.scss";

footer {
  padding-top: 42px;
  padding-bottom: 26px;
  p {
    color: white;
    text-align: center;
    font-size: 12px;
    line-height: 32px;
    margin-top: 13px;
    margin-bottom: 8px;
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    & > * {
      margin-bottom: 10px;
    }
    span {
      color: #fff;
      margin-right: 50px;
    }
    &-brew > a:nth-child(2) {
      margin-left: 23px;
    }
    a {
      display: block;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .footer-social {
    display: flex;
    &-item {
      display: flex;
      margin-right: 30px;
    }
  }

  .footer-links {
    margin: 25px 0;
    position: relative;
    display: flex;
    &-item {
      margin-right: 60px;
      &:last-child {
        margin-right: 0px;
        a {
          &::after {
            content: "";
          }
        }
      }
      a::after {
        content: "/";
        position: absolute;
        right: -33px;
      }
    }
    a {
      color: white;
      display: block;
      text-decoration: none;
      font-size: 17px;
      text-align: left;
      flex: 1;
      position: relative;
    }
  }

  .country {
    margin-right: 14px !important;
  }
}

@media screen and (max-width: $xs-width) {
  footer {
    .footer-links {
      flex-flow: wrap;

      a {
        flex: 0 0 50%;
        margin-top: 20px;

        &:nth-child(2)::after {
          content: "";
        }
      }
    }
    .social {
      flex-flow: wrap;
    }
  }
}
