@import "../../assets/css/variables.scss";

#loaderd {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	&,
	#legal-age-modal {
		background-image: url("/static/images/loader-bottle.png"),
			radial-gradient(circle, #383838 0%, #000000 100%);
		background-repeat: no-repeat;
		background-size: 17% auto, cover;
		background-position: 51.5% bottom, center;
	}
	.progress {
		height: unset;
	}
	svg.loaderd {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		transform: rotate(-90deg);
		margin: auto;

		> circle {
			transform-origin: 50% 50%;
		}
	}

	> .progress {
		color: white;
		font-size: 45px;
		font-weight: bold;
		line-height: 61px;
		position: absolute;
		bottom: 104px;
		transform: rotate(-90deg);
	}

	#legal-age-modal {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 203px;
		animation: fadeIn 0.5s;

		> div {
			display: flex;
			flex-flow: wrap;
			justify-content: center;

			div {
				width: 100%;
				text-align: center;
			}

			p {
				color: white;
				margin-top: 82px;
				margin-bottom: 76px;
				font-size: 30px;
				line-height: 41px;
			}

			button {
				color: white;
				width: 234px;
				margin-bottom: 26px;
			}

			a {
				color: white;
			}
		}
	}

	@media screen and (max-width: $xs-width) {
		& {
			background-size: 60% auto, cover;
			background-position: 60% bottom, center;
		}

		#legal-age-modal {
			padding-bottom: 120px;
			background-size: 60% auto, cover;
			background-position: 60% 130%, center;

			> div {
				p {
					margin-top: 53px;
				}

				button {
					margin-bottom: 17px;
				}
			}
		}
	}
}
.loader {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: saturate(180%) blur(5px);
}
