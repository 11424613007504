#beer-slider {
    position: relative;
    border-left: 1px solid #4C4C4C;
    padding-left: 62px;
    .swiper-container{
        overflow: hidden;
        
    }
    button {
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: black;
        margin: auto 0;
        padding: 0;
        width: 50px;
        height: 50px;
        border: 1px solid #3C3C3C;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 19px;
        }

        &:first-child {
            left: 62px;
        }
    }
    .swiper-button-disabled{
        opacity: 0.5;
    }
    a {
        color: #5C5C5C;
        text-decoration: underline;
        font-size: 20px;
    }
}
@media screen and (max-width: 768px) {
    #beer-slider {
        padding-left: 0 !important;
        border: unset;
        margin-top: 30px;

        button{
            &:first-child {
                left: 4px;
            }
        }
    }
}